<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span>{{ l('编辑日汇总') }}</span>
				</div>
			</div>

			<div class="my-md" style="height: 600px;overflow: auto">
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('出勤日期')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker v-model="entity.attendDate" />
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('班次')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select
							style="width: 90%"
							show-search
							v-model="entity.shiftCode"
							placeholder="班次"
							allowClear
						>
							<a-select-option v-for="item in shiftList" :key="item.code">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('上班时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 90%"
							v-model="entity.dutyFrom"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
					<a-col :span="4">
						<q-label required :label="l('下班时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							style="width: 90%"
							v-model="entity.dutyTo"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('出勤天数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number style="width: 90%" v-model="entity.attDays" :min="0" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('休息天数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number style="width: 90%" v-model="entity.restDays" :min="0" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('应出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number :min="0" v-model="entity.standardLobor"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('实际出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.factLabor" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('夜班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.nightNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('迟到')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number :min="0" v-model="entity.lateMinutes"/></span>
						<span class="suffix"> 分钟</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('早退')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.earlyMinutes" :min="0"/></span>
						<span class="suffix"> 分钟</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('旷工')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.absentHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('加班累计')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.ot" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('工作日加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT1" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('周末加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT2" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('法定加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT3" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('哺乳假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.brHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('丧假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.ssHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('婚假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.hjHours" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('产假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.cjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('年假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.njHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('公出假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.gcHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('工伤假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.gsHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('陪产假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.pcHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('事假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.sjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('病假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.bjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('调休')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.txHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('产检假/时')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.cjjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('因私签卡')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.signCardNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('异常类型')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select allowClear style="width: 90%" v-model="entity.abnormalType" placeholder="异常类型">
							<a-select-option v-for="item in abnormalTypeList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('状态')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select style="width: 90%" v-model="entity.state" placeholder="状态" allowClear>
							<a-select-option v-for="item in dailySummaryStateList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('审核人')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div class="auditUser" @click="auditUser">
							<a-tag
								color="#2db7f5"
								v-for="item in auditUserList"
								:key="item.id"
								style="cursor: pointer;"
							>
								{{ item.realName }}
							</a-tag>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('审核时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							v-model="entity.auditTime"
							style="width: 90%"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('员工确认时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							v-model="entity.selfCheckTime"
							style="width: 90%"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	AttendanceGroupServiceProxy,
	EmpattdailyEditDto,
	EmpattdailyServiceProxy,
} from '../../../../shared/service-proxies';
import { Dic } from '../../../../shared/utils';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import AbpUserSelect from '../../../../components/abp-user-select/abp-user-select';

export default {
	name: 'edit-daily-summary',
	mixins: [ModalComponentBase],
	components: { AbpUserSelect },
	data() {
		return {
			zh_CN,
			empId: undefined,
			id: undefined,
			entity: new EmpattdailyEditDto(),
			abnormalTypeList: [],
			dailySummaryStateList: [],
			shiftList: [],
			auditUserList: [],
		};
	},
	created() {
		this.fullData();
		this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
		this._empattdailyAppService = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.abnormalTypeList = await Dic.getInstance().getDicAsync('QYB_AbnormalType');
		this.dailySummaryStateList = await Dic.getInstance().getDicAsync('QYB_DailySummaryState');
		if (this.empId) {
			this.shiftList = await this.getShiftList();
		}
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._empattdailyAppService
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res;
					if (this.entity.auditUserId && this.entity.auditUserRealName) {
						this.auditUserList = [
							{
								id: this.entity.auditUserId,
								realName: this.entity.auditUserRealName,
							},
						];
					}
					console.log(this.auditUserList);
				});
		},
		getShiftList() {
			return new Promise((resolve) => {
				this.loading = true;
				this._attendanceGroupAppService
					.getShiftInfoCodeByEmpId(this.empId)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		auditUser() {
			ModalHelper.create(
				AbpUserSelect,
				{
					_selectedRowKeys: _.map(this.auditUserList, 'id'),
					_selectedRows: this.auditUserList,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res.length > 0) {
					this.auditUserList = res;
				} else {
					this.auditUserList = [];
				}
			});
		},
		save() {
			if (!this.entity.attendDate) {
				return abp.message.warn('考勤日期不能为空');
			}
			if (!this.entity.shiftCode) {
				return abp.message.warn('班次不能为空');
			}
			if (!this.entity.dutyFrom) {
				return abp.message.warn('上班时间不能为空');
			}
			if (!this.entity.dutyTo) {
				return abp.message.warn('下班时间不能为空');
			}
			if (this.auditUserList.length > 0) {
				this.entity.auditUserId = this.auditUserList[0].id;
			} else {
				this.entity.auditUserId = undefined;
			}
			this.loading = true;
			this._empattdailyAppService
				.update(this.entity)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.suffix {
	padding-left: 5px;
}

.auditUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	width: 90%;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
.auditUser::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}
.auditUser::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.auditUser::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
