<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col class="d-flex align-items-center" :span="6">
					<p class="col-text" style="text-align: right;margin: 0;flex-shrink: 0">出勤日期：</p>
					<a-range-picker
						style="width: 100%;"
						format="YYYY-MM-DD"
						v-model="initialValue"
						@change="onChange"
					/>
				</a-col>
				<a-col :span="3">
					<a-tree-select
						style="width: 100%"
						allowClear
						multiple
						@change="handleCompanyChange"
						:placeholder="l('Company')"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="3">
					<a-tree-select
						style="width: 100%"
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						v-model="departmentId"
						@change="search"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-select v-model="empType" @change="search" style="width: 100%" placeholder="员工类型" allowClear>
						<a-select-option v-for="item in empTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select
						mode="tags"
						show-search
						showArrow
						aria-required="true"
						option-filter-prop="children"
						v-model="attGroupId"
						allowClear
						style="width: 100%"
						@change="search"
						placeholder="考勤组"
					>
						<a-select-option v-for="item in attendanceGroup" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select
						mode="tags"
						show-search
						showArrow
						aria-required="true"
						option-filter-prop="children"
						allowClear
						style="width: 100%"
						placeholder="异常类型"
						v-model="abnormalTypeCode"
						@change="search()"
					>
						<a-select-option v-for="item in abnormalTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select v-model="state" @change="search" style="width: 100%;" placeholder="状态" allowClear>
						<a-select-option v-for="item in dailySummaryStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row :gutter="[8, 8]">
				<a-col :span="12">
					<a-button type="primary" @click="analysis" v-if="isGranted('daily_summary_analysis')">
						分析
					</a-button>
					<a-button type="primary" @click="batchCheck" v-if="isGranted('daily_summary_batchCheck')">
						批量审核
					</a-button>
					<a-button
						type="primary"
						@click="batchChangeStatus"
						v-if="isGranted('daily_summary_batchChangeStatus')"
					>
						<a-icon type="edit" />
						批量修改状态
					</a-button>
					<a-button type="primary" @click="exportFormClick" v-if="isGranted('daily_summary_excel')">
						<a-icon type="download" />
						<span>导出</span>
					</a-button>
					<a-button type="primary" @click="editDailySummary" v-if="isGranted('daily_summary_edit')">
						<a-icon type="edit" />
						<span>编辑</span>
					</a-button>
				</a-col>
				<a-col :span="12" style="justify-content: flex-end" class="d-flex align-items-center ">
					<span style="text-align: right;margin: 0;flex-shrink: 0;">
						<a-checkbox v-model="isOverTime" @change="search">
							只显示有效加班申报人员
						</a-checkbox>
					</span>
					<span style="text-align: right;margin: 0;flex-shrink: 0;">
						<a-checkbox v-model="IsAbnormal" @change="search">
							只显示异常
						</a-checkbox>
					</span>
					<a-input-search
						v-model.trim="filterText"
						placeholder="请输入搜索内容..."
						style="width: 200px;"
						enter-button
						@search="search"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="shiftName" slot-scope="text, record">
					<a-tooltip placement="top">
						<template slot="title" v-if="record.smartShiftCode && record.smartShiftName">
							<div>智能识别班次</div>
						</template>
						<div>{{ record.shiftName }}</div>
						<div style="color: red;font-size: 12px" v-if="record.smartShiftCode && record.smartShiftName">
							*{{ record.smartShiftName }}
						</div>
					</a-tooltip>
				</span>
				<span slot="attDate" slot-scope="text">{{ text.format('YYYY-MM-DD') }}</span>
				<span slot="dutyFrom" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="dutyTo" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="auditTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="selfCheckTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { ModalHelper } from '../../../shared/helpers/modal';
import AttendanceAnalysis from './component/attendance-analysis.vue';
import BatchEdit from './component/batch-edit.vue';
import {
	CompanyServiceProxy,
	DataItemDetailServiceProxy,
	DepartmentServiceProxy,
	EmpattdailyServiceProxy,
	EnterpriseAttendCycleServiceProxy,
	UpdateStateDto,
	AnalysisDtos,
} from '../../../shared/service-proxies';
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import { Dic, fileDownloadService } from '../../../shared/utils';
import * as _ from 'lodash';
import { abpService } from '../../../shared/abp';
import EditDailySummary from './edit-daily-summary/edit-daily-summary';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'daily-summary',
	mixins: [AppComponentBase],

	data() {
		return {
			attendanceGroup: [], //考勤组
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			abnormalTypeList: [], //异常类型
			dailySummaryStateList: [], //状态
			startTime: undefined, //开始时间
			endTime: undefined, //结束时间
			attGroupId: undefined, //考勤组
			state: undefined, //状态
			DailySummaryState: '', //状态(1:待审核、2"已审核、3:员工已确认、4:已结账)--数据字典
			columns: [
				{
					title: '工号',
					width: 100,
					sorter: true,
					dataIndex: 'jobNumber',
					ellipsis: true,
					fixed: 'left',
					align: 'center',
				},
				{
					title: '姓名',
					width: 100,
					sorter: true,
					dataIndex: 'realName',
					ellipsis: true,
					fixed: 'left',
					align: 'center',
				},
				{
					title: '员工类型',
					width: 150,
					sorter: true,
					ellipsis: true,
					dataIndex: 'empType',
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'empType' },
				},
				{
					title: '出勤日期',
					width: 150,
					sorter: true,
					ellipsis: true,
					dataIndex: 'attDate',
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'attDate' },
				},
				{
					title: '班次',
					dataIndex: 'shiftName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'shiftName' },
				},
				{
					title: '上班时间',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'dutyFrom',
					align: 'center',
					scopedSlots: { customRender: 'dutyFrom' },
				},
				{
					title: '下班时间',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'dutyTo',
					align: 'center',
					scopedSlots: { customRender: 'dutyTo' },
				},
				{ title: '出勤天数', sorter: true, dataIndex: 'attDays', ellipsis: true, align: 'center' },
				{ title: '休息天数', sorter: true, dataIndex: 'restDays', ellipsis: true, align: 'center' },
				{ title: '应出勤/时', sorter: true, dataIndex: 'standardLobor', ellipsis: true, align: 'center' },
				{ title: '实际出勤/时', sorter: true, dataIndex: 'factLabor', ellipsis: true, align: 'center' },
				{ title: '夜班/次', sorter: true, dataIndex: 'nightNums', ellipsis: true, align: 'center' },
				{ title: '迟到/分钟', dataIndex: 'lateMinutes', sorter: true, align: 'center' },
				{ title: '早退/分钟', dataIndex: 'earlyMinutes', sorter: true, align: 'center' },
				{ title: '旷工/时', dataIndex: 'absentHours', sorter: true, align: 'center' },
				{ title: '加班累计/时', sorter: true, dataIndex: 'ot', align: 'center' },
				{ title: '工作日加班/时', sorter: true, width: 150, dataIndex: 'oT1', align: 'center' },
				{ title: '周末加班/时', sorter: true, dataIndex: 'oT2', align: 'center' },
				{ title: '法定加班/时', sorter: true, dataIndex: 'oT3', align: 'center' },
				{ title: '哺乳假/时', sorter: true, dataIndex: 'brHours', align: 'center' },
				{ title: '丧假/时', sorter: true, dataIndex: 'ssHours', align: 'center' },
				{ title: '婚假/次', sorter: true, dataIndex: 'hjHours', align: 'center' },
				{ title: '产假/时', sorter: true, dataIndex: 'cjHours', align: 'center' },
				{ title: '年假/时', sorter: true, dataIndex: 'njHours', align: 'center' },
				{ title: '公出假/时', sorter: true, dataIndex: 'gcHours', align: 'center' },
				{ title: '工伤假/时', sorter: true, dataIndex: 'gsHours', align: 'center' },
				{ title: '陪产假/时', sorter: true, dataIndex: 'pcHours', align: 'center' },
				{ title: '产检假/时', sorter: true, dataIndex: 'cjjHours', align: 'center' },
				{ title: '事假/时', sorter: true, dataIndex: 'sjHours', align: 'center' },
				{ title: '病假/时', sorter: true, dataIndex: 'bjHours', align: 'center' },
				{ title: '调休/时', sorter: true, dataIndex: 'txHours', align: 'center' },

				{ title: '因私签卡/次', sorter: true, dataIndex: 'signCardNums', align: 'center' },
				{ title: '异常类型', sorter: true, dataIndex: 'abnormalType', ellipsis: true, align: 'center' },
				{ title: '状态', sorter: true, dataIndex: 'state', ellipsis: true, align: 'center' },
				{ title: '审核人', sorter: true, dataIndex: 'auditUserName', ellipsis: true, align: 'center' },
				{
					title: '审核时间',
					width: 200,
					sorter: true,
					dataIndex: 'auditTime',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'dutyFrom' },
				},
				{
					title: '员工确认时间',
					width: 200,
					sorter: true,
					dataIndex: 'selfCheckTime',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'selfCheckTime' },
				},
			],
			tableData: [],

			attenanceDateApi: undefined,
			initialValue: undefined, //出勤日期初始值
			dictionaryListApi: undefined,
			_CompanyConfigServiceProxy: undefined, //公司接口
			_DepartmentServiceProxy: undefined, //部门接口
			groupListApi: undefined, //考勤组
			_fileDownloadService: undefined, //导出
			ids: [], //批量的ids
			currentPeriod: {}, //当前考勤周期
			abnormalTypeCode: undefined,
			IsAbnormal: false,
			empTypeList: [],
			empType: undefined,
			isOverTime: false,

			//多选
			isMultiple: true,
		};
	},
	components: {
		AttendanceAnalysis,
		BatchEdit,
		EditDailySummary,
	},
	created() {
		// 出勤日期
		this.attenanceDateApi = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		// 字典
		this.dictionaryListApi = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.columns = this.getColumn('考勤日汇总', this.columns, 150);
	},
	async mounted() {
		this.abnormalTypeList = await Dic.getInstance().getDicAsync('QYB_AbnormalType');
		this.dailySummaryStateList = await Dic.getInstance().getDicAsync('QYB_DailySummaryState');
		this.empTypeList = await Dic.getInstance().getDicAsync('EmpType');
		this.initCompany();
		// this.getData();

		this.attendanceGroup = await this.initAttGroup();
		await this.getTheData();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		async getTheData() {
			// this.groupListApi.getPaged()
			let a = await this.initAttDate();
			this.startTime = a.startTime;
			this.endTime = a.endTime;
			if (abpService.abp.userInfo.companyId) {
				this.comPanyId = abpService.abp.userInfo.companyId;
				this.handleCompanyChange(this.comPanyId);
			} else {
				this.getData();
			}
		},
		// 初始化出勤日期
		initAttDate() {
			return new Promise((resolve, reject) => {
				this.attenanceDateApi.getCycleByNow().then((res) => {
					this.initialValue = [moment(res.startTime), moment(res.endTime)];
					this.currentPeriod = res;
					resolve(res);
				});
			});
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},

		//初始化考勤组
		async initAttGroup() {
			return new Promise((resolve) => {
				this.groupListApi.getGroupListByManage().then((res) => {
					resolve(res);
				});
			});
		},
		//时间段
		onChange(date, dateString) {
			// console.log(date, dateString);
			this.startTime = dateString[0];
			this.endTime = dateString[1];
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		//
		// 公司选择
		handleCompanyChange(value) {
			this.comPanyId = value;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		//分析
		analysis() {
			ModalHelper.create(
				AttendanceAnalysis,
				{
					startTime: this.startTime ? this.startTime : this.currentPeriod.startTime,
					endTime: this.endTime ? this.endTime : this.currentPeriod.endTime,
				},
				{
					width: '70%',
				}
			).subscribe((res) => {
				this.getData();
			});
		},
		//批量修改状态
		batchChangeStatus() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			ModalHelper.create(
				BatchEdit,
				{
					ids: this.selectedRowKeys,
				},
				{
					width: '30%',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		//批量审核
		batchCheck() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('至少选择一项进行操作');
			}
			let rows = this.selectedRows.filter((item) => item.state == '待审核');
			let ids = _.map(rows, 'id');
			this.$confirm({
				title: '审核确认',
				content: '确认要执行此操作吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					let entity = new UpdateStateDto();
					entity.ids = ids;
					entity.stateCode = '2';
					this.groupListApi.updateState(entity).then((res) => {
						this.$notification['success']({
							message: this.l('SavedSuccessfully'),
						});
						this.getData();
					});
				},
			});
		},
		getData() {
			this.loading = true;
			this.selectedRowObj = {};
			this.groupListApi
				.getPgeds(
					this.startTime ? moment(this.startTime) : undefined,
					this.endTime ? moment(this.endTime) : undefined,
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.abnormalTypeCode,
					this.state,
					this.IsAbnormal,
					this.empType,
					this.isOverTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		exportFormClick() {
			this.groupListApi
				.getToExcelFile(
					this.startTime ? moment(this.startTime) : undefined,
					this.endTime ? moment(this.endTime) : undefined,
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.abnormalTypeCode,
					this.state,
					this.IsAbnormal,
					this.empType,
					this.isOverTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.search();
		},
		rowClassName(record, index) {
			if (this.selectedRowObj[index + 'r' + record.id] && record.abnormalTypeCode) {
				//类在 style/qyb.less
				return 'selected red-color';
			}
			if (this.selectedRowObj[index + 'r' + record.id]) {
				return 'selected';
			}
			if (record.abnormalTypeCode) {
				return 'red-color';
			}
		},

		editDailySummary() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRows.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			ModalHelper.create(
				EditDailySummary,
				{
					id: this.selectedRows[0].id,
					empId: this.selectedRows[0].empId,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
	},
};
</script>

<style>
.red-color {
	color: red;
}
.margin-left-10 {
	margin-left: 10px;
}
.pagination {
	margin: 10px auto;
	text-align: right;
}

.edit-cell {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cell-text {
	flex: 4;
	max-width: 100px;
	min-width: 100px;
}

.cell-iocn {
	padding-left: 2px;
	flex: 1;
	max-width: 20px;
	min-width: 20px;
	cursor: pointer;
}

.editable-cell-icon {
	display: none;
}

.edit-cell:hover .editable-cell-icon {
	color: #108ee9;
	display: inline-block;
}
</style>
