<template>
	<a-spin :spinning="spinning">
		<a-config-provider :locale="zhCN">
			<div>
				<div class="modal-header">
					<div class="modal-title">
						<div>考勤数据分析</div>
					</div>
				</div>
				<div>
					<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="save">
						<a-form-item label="出勤日期:" style="text-align: left">
							<a-range-picker
								format="YYYY-MM-DD"
								v-decorator="[
									'attDate',
									{
										initialValue: initialValue,
										rules: [{ type: 'array', required: true, message: '请选择出勤日期!' }],
									},
								]"
								@change="onChange"
							/>
						</a-form-item>
						<a-row>
							<a-col>
								<a-form-item label="人员：" style="text-align: left">
									<div class="CheckInUser" @click="checkInUser">
										<a-tag
											color="#2db7f5"
											v-for="item in CheckInUserList"
											:key="item.userId"
											style="cursor: pointer;"
										>
											{{ item.realName }}
										</a-tag>
									</div>
								</a-form-item>
							</a-col>
						</a-row>

						<a-form-item label="公司：" style="text-align: left">
							<a-tree-select
								class="left"
								allowClear
								@change="handleCompanyChange"
								:placeholder="l('Company')"
								style="width:200px;"
								tree-default-expand-all
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								v-model="comPanyId"
							/>
						</a-form-item>
						<a-form-item label="部门：" style="text-align: left">
							<a-tree-select
								v-model="departmentId"
								allowClear
								style="width:200px;"
								show-search
								:filterTreeNode="searchTree"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="DepTreeData"
								:placeholder="l('Department')"
								tree-default-expand-all
								@select="SelectDepartmentTree"
							>
							</a-tree-select>
						</a-form-item>
						<a-form-item label="考勤组：" style="text-align: left">
							<a-select
								style="width:200px;"
								v-decorator="['attGroupId', {}]"
								allowClear
								@change="attGroupChange"
							>
								<a-select-option :value="item.key" v-for="item in attendanceGroup" :key="item.key">
									{{ item.label }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-form>
				</div>
				<!-- 按钮 -->
				<div class="modal-footer">
					<a-button @click="close">
						{{ l('Cancel') }}
					</a-button>
					<a-button :type="'primary'" @click="save">
						分析
					</a-button>
				</div>
			</div>
		</a-config-provider>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';
import {
	AnalysisDtos,
	AttendancePeopleEditDto,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpattdailyServiceProxy,
	EmpattmonthlyAnalysisDtos,
	EmpattmonthlyServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { abpService } from '../../../../shared/abp';

export default {
	name: 'attendance-analysis',
	mixins: [ModalComponentBase],
	props: {},
	data() {
		return {
			zhCN,
			spinning: false,
			form: this.$form.createForm(this, { name: 'coordinated' }),
			CheckInUserList: [],
			startTime: undefined,
			endTime: undefined,
			initialValue: null, //出勤日期初始值
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			_CompanyConfigServiceProxy: undefined,
			_DepartmentServiceProxy: undefined,
			groupListApi: undefined,
			attendanceGroup: [], //考勤组
			attGroupId: undefined,
			isMonth: false, //是否是月汇总分析
			_empattmonthlyServiceProxy: undefined, //月考勤
		};
	},
	created() {
		this.fullData();
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.initialValue = [moment(this.startTime), moment(this.endTime)];
		// console.log(this.companyTreeList, 9, this.comPanyId, this.startTime, this.isMonth)
		this.initCompany();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
		}
		this.initAttGroup();
	},
	components: {
		SelectUserTag,
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		save() {
			this.form.validateFields((err, fieldsValue) => {
				if (!err) {
					let entity = new AnalysisDtos();
					entity.startTime = this.initialValue[0];
					entity.endTime = this.initialValue[1];
					let ids = '';
					this.CheckInUserList.forEach((item) => {
						ids += item.userId + ',';
					});
					entity.userIds = ids;
					entity.companyId = this.comPanyId;
					entity.departmentId = this.departmentId;
					entity.attGroupId = this.attGroupId;
					this.spinning = true;
					this.groupListApi.analysis(entity).then((res) => {
						this.spinning = false;
						this.success(true);
						this.close();
					});
				}
			});
		},
		onChange(date, dateString) {
			// console.log(date, dateString)
			this.startTime = dateString[0];
			this.endTime = dateString[1];
		},
		handleSelectChange(e) {
			console.log(e);
		},
		/**
		 * 参与考勤人员
		 */
		checkInUser() {
			let list = [];
			if (this.CheckInUserList.length > 0) {
				list = this.CheckInUserList.map((item) => {
					return { id: item.userId, realName: item.realName };
				});
			}

			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					isMultiple: true,
					_selectedRowKeys: _.map(list, 'id'),
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.CheckInUserList = res.map((item) => {
						let entity = new AttendancePeopleEditDto();
						entity.userId = item.id;
						entity.realName = item.realName;
						entity.isCheckIn = true;
						return entity;
					});
				}
			});
		},
		// 公司选择
		handleCompanyChange(value) {
			this.comPanyId = value;
			// this.getData();
			if (!value) {
				this.departmentId = undefined;
			}
			this.getDepTreeData();
		},

		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = res;
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							let obj = {
								key: item.key,
								value: item.key.toString(),
								title: item.title,
							};
							this.DepTreeData.push(obj);
						}
					});
					this.initDepTree(this.DepTreeData);
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initDepTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.DepTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.key,
							value: m.key.toString(),
							title: m.title,
						};
						v.children.push(obj); //放进父级的集合中
						this.initDepTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		//初始化考勤组
		initAttGroup() {
			this.groupListApi.getGroupListByManage().then((res) => {
				res.forEach((item) => {
					this.attendanceGroup.push({
						key: item.id,
						label: item.name,
					});
				});
			});
		},
		attGroupChange(e) {
			this.attGroupId = e;
			// this.getData();
		},
	},
};
</script>

<style scoped>
.CheckInUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
</style>
